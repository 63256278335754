// assets
import BannerDiamonds from "../assets/images/banners/diamonds.jpg";
import BannerJewelry from "../assets/images/banners/jewelry.jpg";

import DiamondIconAsscher from "../shared/assets/images/diamonds/shapes/icons/asscher.webp";
import DiamondIconCushion from "../shared/assets/images/diamonds/shapes/icons/cushion.webp";
import DiamondIconEmerald from "../shared/assets/images/diamonds/shapes/icons/emerald.webp";
import DiamondIconHeart from "../shared/assets/images/diamonds/shapes/icons/heart.webp";
import DiamondIconMarquise from "../shared/assets/images/diamonds/shapes/icons/marquise.webp";
import DiamondIconOval from "../shared/assets/images/diamonds/shapes/icons/oval.webp";
import DiamondIconPear from "../shared/assets/images/diamonds/shapes/icons/pear.webp";
import DiamondIconPrincess from "../shared/assets/images/diamonds/shapes/icons/princess.webp";
import DiamondIconRadiant from "../shared/assets/images/diamonds/shapes/icons/radiant.webp";
import DiamondIconRound from "../shared/assets/images/diamonds/shapes/icons/round.webp";

import JewelrySketchAnklet from "../shared/assets/images/jewelry/categories/w-anklet.png";
import JewelrySketchBracelet from "../shared/assets/images/jewelry/categories/w-bracelet.png";
import JewelrySketchBrooch from "../shared/assets/images/jewelry/categories/w-brooch.png";
import JewelrySketchCharm from "../shared/assets/images/jewelry/categories/w-charm.png";
import JewelrySketchCufflink from "../shared/assets/images/jewelry/categories/w-cufflink.png";
import JewelrySketchEarring from "../shared/assets/images/jewelry/categories/w-earring.png";
import JewelrySketchNecklace from "../shared/assets/images/jewelry/categories/w-necklace.png";
import JewelrySketchPendant from "../shared/assets/images/jewelry/categories/w-pendant.png";
import JewelrySketchRing from "../shared/assets/images/jewelry/categories/w-ring.png";
import JewelrySketchStud from "../shared/assets/images/jewelry/categories/w-stud.png";

export const categories = {
  diamonds: {
    title: "SEARCH FOR LOOSE DIAMONDS",
    subtitle:
      "All diamonds in our inventory are certified through either GIA or EGL USA.",
    banner: BannerDiamonds,
    path: "/diamonds",
    param: "shape",
    imageCssClasses: "object-cover w-16",
    categories: [
      {
        name: "Round",
        code: "RD",
        image: DiamondIconRound,
      },
      {
        name: "Oval",
        code: "OV",
        image: DiamondIconOval,
      },
      {
        name: "Cushion",
        code: "CU",
        image: DiamondIconCushion,
      },
      {
        name: "Pear",
        code: "PR",
        image: DiamondIconPear,
      },
      {
        name: "Princess",
        code: "PS",
        image: DiamondIconPrincess,
      },
      {
        name: "Emerald",
        code: "EC",
        image: DiamondIconEmerald,
      },
      {
        name: "Marquise",
        code: "MQ",
        image: DiamondIconMarquise,
      },
      {
        name: "Asscher",
        code: "AS",
        image: DiamondIconAsscher,
      },
      {
        name: "Radiant",
        code: "RA",
        image: DiamondIconRadiant,
      },
      {
        name: "Heart",
        code: "HS",
        image: DiamondIconHeart,
      },
    ],
  },
  jewelry: {
    title: "Browse By Category",
    subtitle: "Discover our exquisite jewelry collection",
    banner: BannerJewelry,
    path: "/jewelry",
    param: "category",
    imageCssClasses: "object-none w-16",
    categories: [
      {
        name: "Rings",
        code: "Ring",
        image: JewelrySketchRing,
        // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Diamond-Ring-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan.png",
        // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-ring-accessories-dreamstale-lineal-dreamstale.png",
      },
      {
        name: "Earrings",
        code: "Earring",
        image: JewelrySketchEarring,
        // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Earrings-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan-4.png",
        // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-earrings-accessories-dreamstale-lineal-dreamstale.png",
      },
      {
        name: "Studs",
        code: "Stud",
        image: JewelrySketchStud,
        // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Earrings-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan-2.png",
        // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-earring-accessories-dreamstale-lineal-dreamstale.png",
      },
      {
        name: "Necklaces",
        code: "Necklace",
        image: JewelrySketchNecklace,
        // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Necklace-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan-2.png",
        // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-necklace-accessories-dreamstale-lineal-dreamstale.png",
      },
      {
        name: "Pendants",
        code: "Pendant",
        image: JewelrySketchPendant,
        // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Brooch-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan.png",
        // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-necklace-accessories-dreamstale-lineal-dreamstale-1.png",
      },
      {
        name: "Bracelets",
        code: "Bracelet",
        image: JewelrySketchBracelet,
        // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Bracelet-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan-2.png",
        // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-bracelet-accessories-dreamstale-lineal-dreamstale.png",
      },
      {
        name: "Charms",
        code: "Charm",
        image: JewelrySketchCharm,
        // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Gemstone-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan-5.png",
        // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-tag-accessories-dreamstale-lineal-dreamstale.png",
      },
      {
        name: "Anklets",
        code: "Anklet",
        image: JewelrySketchAnklet,
        // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Bracelet-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan.png",
        // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-scarf-accessories-dreamstale-lineal-dreamstale.png",
      },
      {
        name: "Brooches",
        code: "Brooch",
        image: JewelrySketchBrooch,
        // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Brooch-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan-6.png",
        // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-mirror-accessories-dreamstale-lineal-dreamstale.png",
      },
      {
        name: "Cufflinks",
        code: "Cufflink",
        image: JewelrySketchCufflink,
        // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Stud-Earrings-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan.png",
        // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-slippers-accessories-dreamstale-lineal-dreamstale.png",
      },
    ],
  },
};

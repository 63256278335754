// packages
import _ from "lodash";
import { MdOutlineClose } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import axios from "axios";

// components
import Alert from "../shared/components/Alert";

// utils
import { fetchProfile } from "../shared/utils/user";

const PROFILE_KEY = "profile";
const TOKEN_KEY = "token";

const DEFAULT_THEME = {
  button: {
    primary:
      "bg-theme-blue text-white rounded-md hover:bg-black active:bg-black/50",
  },
  header: "bg-theme-blue text-white rounded-t-md",
  input:
    "border-gray-400 bg-gray-50 text-gray-800 rounded-md focus:border-theme-blue",
  label: "text-gray-800",
  overlay: "bg-black/40",
  window: "bg-white rounded-md",
  checkbox:
    "border-gray-400 bg-gray-50 rounded hover:bg-gray-200 checked:bg-theme-blue checked:hover:bg-theme-blue/50 checked:focus:bg-theme-blue/50",
};

export default function ModalLogin({
  header,
  isModalOpen,
  setIsModalOpen,
  theme = {},
  onSuccess,
}) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);

  const loginFormRef = useRef();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setAlertVisible(false);

    const formData = new FormData(loginFormRef.current);
    const entries = Object.fromEntries(formData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          user: { email: entries.email, password: entries.password },
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );

      localStorage.setItem(TOKEN_KEY, response.headers.authorization);

      const profile = await fetchProfile();
      if (profile) {
        localStorage.setItem(PROFILE_KEY, JSON.stringify(profile));
      }
      navigate("");
      onSuccess();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error signing in:", error);
      setAlertVisible(true);
      setAlertMessage("Error signing in, please try again.");
    }
  };

  return (
    isModalOpen && (
      <div
        aria-hidden="true"
        className={`${activeTheme.overlay} fixed left-0 right-0 top-0 z-50 h-full max-h-full w-full overflow-y-auto overflow-x-hidden max-md:px-2 md:inset-0`}
        id="defaultModal"
        tabIndex="-1"
      >
        <div className="relative flex h-full max-h-full w-full items-center justify-center">
          <div className={`${activeTheme.window} relative w-full lg:w-1/3`}>
            {/* header */}
            <div
              className={`${activeTheme.header} flex items-center justify-between p-2 pb-4 md:p-4 md:pb-6`}
            >
              <h3 className="text-xl font-semibold">{header}</h3>
              <button
                className="inline-flex items-center justify-center bg-transparent hover:bg-white/10"
                onClick={() => setIsModalOpen(false)}
                type="button"
              >
                <MdOutlineClose size="20" className="shrink-0" />
              </button>
            </div>
            {/* content */}
            {alertVisible && (
              <Alert
                content={alertMessage}
                type="error"
                visible={alertVisible}
              />
            )}
            <form
              className="space-y-4 p-6"
              onSubmit={handleLogin}
              ref={loginFormRef}
            >
              <div>
                <label
                  className={`${activeTheme.label} block text-sm font-medium`}
                  htmlFor="lEmail"
                >
                  Email
                </label>
                <input
                  className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
                  id="lEmail"
                  name="email"
                  placeholder="Your email address"
                  required="required"
                  type="email"
                />
              </div>
              <div>
                <label
                  className={`${activeTheme.label} block text-sm font-medium`}
                  htmlFor="lPassword"
                >
                  Password
                </label>
                <input
                  className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
                  id="lPassword"
                  name="password"
                  placeholder="Your password"
                  required="required"
                  type="password"
                />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      className={`${activeTheme.checkbox} h-4 w-4 border ring-0 focus:ring-0 active:ring-0`}
                      id="lRemember"
                      type="checkbox"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      className={`${activeTheme.label} block text-sm font-medium`}
                      htmlFor="lRemember"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                <NavLink
                  className={`${activeTheme.link} text-sm font-medium`}
                  exact="exact"
                  to="#"
                >
                  Forgot password?
                </NavLink>
              </div>
              <button
                className={`${activeTheme.button.primary} w-full px-5 py-2.5 text-center text-sm font-bold focus:ring-0`}
                type="submit"
              >
                Sign In
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  );
}

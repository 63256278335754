// packages
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import _ from "lodash";

import {
  MdOutlineAttachFile,
  MdOutlineUploadFile,
  MdOutlineClose,
  MdOutlineSend,
} from "react-icons/md";

// components
import Alert from "../shared/components/Alert";
import Header from "../components/Header";
import ItemDetails from "../shared/components/ItemDetails";
import LoadingIndicator from "../shared/components/LoadingIndicator";
import StandardContainer from "../components/StandardContainer";

// utils
import { isCompanyOwner, isSuperAdminUser } from "../shared/utils/user";
import { process } from "../data/process";

const DEFAULT_THEME = {
  // own settings
  button: {
    primary:
      "bg-theme-blue text-white rounded-md hover:bg-black active:bg-black/50",
  },
  link: "text-theme-blue hover:text-gray-800 hover:underline",
  // component settings
  tabTheme: {},
};

export default function Company({ theme = {} }) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  const [loading, setLoading] = useState(true);

  // data
  const [companySettings, setCompanySettings] = useState(null);
  const [data, setData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  // profile
  useEffect(() => {
    const profile = JSON.parse(localStorage.profile);
    setUserRole(profile.role);
  }, []);

  // company
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/companies/${process.env.REACT_APP_COMPANY_ID}.json`,
        {
          headers: {
            Authorization: localStorage.token,
          },
        },
      )
      .then((response) => {
        setData(response.data);
        setLogo(response.data.logo);
      })
      .catch((error) => {
        console.error("Error fetching company:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // company_settings
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/companies/${process.env.REACT_APP_COMPANY_ID}/company_settings.json`,
        {
          headers: {
            Authorization: localStorage.token,
          },
        },
      )
      .then((response) => {
        setCompanySettings(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching company:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companySettings]);

  // logo
  const [logo, setLogo] = useState("");
  const [newLogo, setNewLogo] = useState("");
  const [isSubmittable, setIsSubmittable] = useState(false);

  const handleAddFile = (e) => {
    setIsSubmittable(true);
    setNewLogo(e.target.files[0]);
  };

  const handleUpload = () => {
    if (!newLogo) {
      return;
    }

    const formData = new FormData();
    formData.append("company[logo]", newLogo);

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/companies/${process.env.REACT_APP_COMPANY_ID}`,
        formData,
        {
          headers: {
            Authorization: localStorage.token,
          },
        },
      )
      .then((response) => {
        setIsSubmittable(false);
        setLogo(response.data.logo);
        setNewLogo("");
      })
      .catch((error) => console.error("Error uploading logo:", error));
  };

  // From Email Address && Quote Email Address Update
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [companySettingsId, setCompanySettingsId] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [updateAttribute, setUpdateAttribute] = useState("");
  const [updateSuccessful, setUpdateSuccessful] = useState(false);

  const openModal = (type, settingsId) => {
    setCompanySettingsId(settingsId);
    setModalVisible(true);
    setUpdateAttribute(type);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleUpdate = async (e, attribute, data, formData, successMessage) => {
    e.preventDefault();
    setAlertVisible(false);
    const setting_name = updateAttribute;
    const setting_value = formData.get(updateAttribute);
    const settingID = formData.get(companySettingsId);
    const updatedEmail = formData.get(updateAttribute);

    try {
      if (
        updateAttribute != "from_email_address" &&
        updateAttribute != "quote_email_address"
      ) {
        const response = await axios.patch(
          `${process.env.REACT_APP_API_URL}/companies/${process.env.REACT_APP_COMPANY_ID}/company_settings/${settingID}.json`,
          {
            company_setting: {
              setting_name: setting_name,
              setting_value: setting_value,
            },
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          },
        );
      } else {
        const response = await axios.patch(
          `${process.env.REACT_APP_API_URL}/companies/${process.env.REACT_APP_COMPANY_ID}.json`,
          {
            company: {
              [updateAttribute]: updatedEmail,
            },
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          },
        );
      }

      setAlertMessage(successMessage);
      setUpdateSuccessful(true);
    } catch (error) {
      console.error(`Error updating ${attribute}:`, error);
      setAlertMessage(`Error updating emails, please try again.`);
      setUpdateSuccessful(false);
    } finally {
      closeModal();
      setAlertVisible(true);
    }
  };
  const form = () => {
    const handleSubmit = async (e) => {
      const formData = new FormData(e.target);
      const data = {};

      formData.forEach((value, key) => {
        data[key] = value;
      });

      handleUpdate(
        e,
        updateAttribute,
        data,
        formData,
        `Your ${updateAttribute.replace(/_/g, " ")} has been updated.`,
      );
    };

    return (
      <div
        aria-hidden="true"
        className="fixed left-0 right-0 top-0 z-50 h-[calc(100%-1rem)] max-h-full w-full overflow-y-auto overflow-x-hidden bg-black/50 p-4 md:inset-0"
        id="defaultModal"
        tabIndex="-1"
      >
        <div className="relative flex h-full max-h-full w-full items-center justify-center">
          <div className="relative w-full rounded-md bg-white shadow lg:w-1/2">
            <form onSubmit={handleSubmit}>
              {/* header */}
              <div
                className={`${activeTheme.container} flex items-center justify-between rounded-t border-b p-4 text-white`}
              >
                <h3 className="text-xl font-semibold">
                  {_.startCase(updateAttribute)}
                </h3>
                <button
                  className="inline-flex h-8 w-8 items-center justify-center rounded-md bg-transparent hover:bg-white/10"
                  onClick={closeModal}
                  type="button"
                >
                  <MdOutlineClose size="24" className="shrink-0" />
                </button>
              </div>
              {/* body */}
              <div className="space-y-4 p-4">
                <label
                  className="mb-2 block text-sm font-medium text-gray-900"
                  htmlFor={updateAttribute}
                >
                  {_.startCase(updateAttribute)}
                </label>
                <input
                  className={`${activeTheme.input} block w-full rounded-md border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900`}
                  id={updateAttribute}
                  minLength={updateAttribute === "password" ? 8 : ""}
                  name={updateAttribute}
                  placeholder={
                    updateAttribute == "from_email_address" ||
                    updateAttribute == "quote_email_address"
                      ? "example@gmail.com"
                      : ""
                  }
                  type={
                    updateAttribute == "from_email_address" ||
                    updateAttribute == "quote_email_address"
                      ? "email"
                      : "text"
                  }
                  // value={}
                  required
                />
                {isSuperAdminUser(userRole) && (
                  <input
                    hidden
                    name={companySettingsId}
                    defaultValue={companySettingsId}
                  />
                )}
              </div>
              {/* footer */}
              <div className="flex items-center justify-end space-x-2 border-gray-200 px-4 pb-2">
                <button
                  className={`${activeTheme.button.primary} group flex items-center rounded-md px-3 py-2 text-white hover:bg-black`}
                  type="submit"
                >
                  Update
                  <MdOutlineSend
                    className="ml-2 shrink-0 group-hover:scale-[1.2]"
                    size="16"
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const content = loading ? (
    <LoadingIndicator message="Loading company details" />
  ) : (
    <>
      {data && (
        <ItemDetails
          item={data}
          keys={[
            "company",
            "customer_number",
            "phone",
            "address1",
            "address2",
            "city",
            "state",
            "zip",
            "country",
          ]}
          gridClass={"grid-rows-3 grid-flow-col"}
        />
      )}
      {isCompanyOwner(userRole) && (
        <div className="my-2 rounded-md bg-white p-4">
          <div className="grid items-center gap-2 md:grid-cols-3 md:gap-4">
            <h2 className="text-2xl font-bold leading-none text-theme-blue">
              Company Logo
            </h2>
            <label
              className="flex cursor-pointer items-center rounded-md bg-black px-2 py-2.5 text-sm font-medium text-white hover:bg-theme-green-light"
              htmlFor="logo"
            >
              <MdOutlineAttachFile className="mr-1 shrink-0" size="20" />
              {newLogo ? (
                <span className="break-all text-sm">{newLogo.name}</span>
              ) : (
                <span>Select File</span>
              )}
              <input
                accept=".jpg, .jpeg, .png, .svg"
                hidden
                id="logo"
                name="logo"
                onChange={handleAddFile}
                type="file"
              />
            </label>
            <button
              className={`${
                isSubmittable
                  ? `${activeTheme.button.primary} cursor-pointer text-white hover:bg-black`
                  : "cursor-not-allowed bg-gray-400 text-black"
              } flex w-full items-center rounded-md px-2 py-2.5 text-sm font-medium`}
              disabled={!isSubmittable}
              onClick={handleUpload}
            >
              <MdOutlineUploadFile className="mr-1 shrink-0" size="20" />
              {logo ? "Update" : "Upload"} Logo
            </button>
          </div>
          {logo && (
            <img
              alt="Company Logo"
              className="mt-4 w-full object-cover"
              src={logo.url}
            />
          )}
        </div>
      )}
      {alertVisible && (
        <Alert
          content={alertMessage}
          type={updateSuccessful ? "success" : "error"}
          visible={alertVisible}
        />
      )}
      {modalVisible && form()}
      {isSuperAdminUser(userRole) && (
        <div className="mt-2 grid grid-cols-3">
          <ul className="rounded-md bg-gray-50 py-2 text-sm max-md:mt-2">
            {companySettings &&
              companySettings.map((item, index) => {
                return (
                  <li
                    className="flex justify-between border-b-2 border-white px-2 py-2"
                    key={index}
                  >
                    <span className="font-semibold">
                      {_.startCase(item.setting_name)}
                    </span>
                    <a
                      className={`${activeTheme.link}`}
                      href="#"
                      onClick={() => openModal(item.setting_name, item.id)}
                    >
                      Update
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {isSuperAdminUser(userRole) ||
        (isCompanyOwner(userRole) && (
          <div className="mt-2 gap-x-2 rounded-md p-4 md:grid md:grid-cols-3">
            <ul className="rounded-md bg-gray-50 py-2 text-sm max-md:mt-2">
              <li className="flex justify-between border-b-2 border-white px-4 py-2">
                <span className="font-semibold">From Email Address</span>
                <a
                  className={`${activeTheme.link}`}
                  href="#"
                  onClick={() => openModal("from_email_address")}
                >
                  Update
                </a>
              </li>
              <li className="flex justify-between px-4 py-2">
                <span className="font-semibold">Quote Email Address</span>
                <a
                  className={`${activeTheme.link}`}
                  href="#"
                  onClick={() => openModal("quote_email_address")}
                >
                  Update
                </a>
              </li>
            </ul>
          </div>
        ))}
    </>
  );

  return (
    <>
      <Header title="Company Details" />
      <StandardContainer content={content} />
    </>
  );
}

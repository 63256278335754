// packages
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

// components
import ModalLogin from "../../components/ModalLogin";

// utils
import { isTokenValid } from "../utils/auth";

const PROFILE_KEY = "profile";
const TOKEN_KEY = "token";

export default function ItemGrid({
  similar,
  data,
  limit = false,
  showSummary = false,
}) {
  const selectedData = limit ? data.slice(0, limit) : data;
  const [isLoginModal, setIsLoginModal] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (isTokenValid()) {
      setIsAuthenticated(true);
    }
  }, [localStorage.getItem(TOKEN_KEY)]);

  return (
    <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:gap-4 lg:grid-cols-4">
      {selectedData.map((item, index) => {
        return (
          <div
            key={index}
            className="group border border-black/10  text-black hover:border-theme-blue-logo"
          >
            {similar ? (
              <div>
                <div className=" py-6">
                  <a
                    href={item.route}
                    className="flex h-36 w-full justify-center overflow-hidden hover:border-none hover:border-transparent"
                    target="_blank"
                  >
                    <img
                      alt={item.images.main.alt}
                      className="max-h-[90%] max-w-[90%] object-contain transition-all duration-300 group-hover:scale-110"
                      loading="lazy"
                      src={item.images.main.src}
                    />
                  </a>
                </div>
                <div className="flex flex-col text-xs">
                  <div className="flex justify-between bg-theme-blue-logo px-4 py-2 text-white">
                    <div className="w-1/2">{item.data.item_no}</div>
                    <div className="w-1/2">{item.shape}</div>
                  </div>
                  <div className="flex justify-between bg-gray-100 px-4 py-2">
                    <div className="w-1/2">Carat</div>
                    <div className="w-1/2">{item.carats}</div>
                  </div>
                  <div className="flex justify-between bg-gray-300 px-4 py-2">
                    <div className="w-1/2">Color</div>
                    <div className="w-1/2">{item.color}</div>
                  </div>
                  <div className="flex justify-between bg-gray-100 px-4 py-2">
                    <div className="w-1/2">Clarity</div>
                    <div className="w-1/2">{item.clarity}</div>
                  </div>
                  <div className="flex justify-between bg-gray-300 px-4 py-2">
                    <div className="w-1/2">Cut</div>
                    <div className="w-1/2">{item.cut}</div>
                  </div>
                  <div className="flex justify-between bg-gray-100 px-4 py-2">
                    <div className="w-1/2">Polish</div>
                    <div className="w-1/2">{item.polish}</div>
                  </div>
                  <div className="flex justify-between bg-gray-300 px-4 py-2">
                    <div className="w-1/2">Symmetry</div>
                    <div className="w-1/2">{item.symmetry}</div>
                  </div>
                  <div className="flex justify-between bg-gray-100 px-4 py-2">
                    <div className="w-1/2">Fluorescence</div>
                    <div className="w-1/2">{item.fluorescence}</div>
                  </div>
                  <div className="flex justify-between bg-gray-300 px-4 py-2">
                    <div className="w-1/2">Measurements</div>
                    <div className="w-1/2">{item.measurements}</div>
                  </div>
                  <div className="flex justify-between bg-gray-100 px-4 py-2">
                    <div className="w-1/2">Depth</div>
                    <div className="w-1/2">{item.depth}</div>
                  </div>
                  <div className="flex justify-between bg-gray-300 px-4 py-2">
                    <div className="w-1/2">Table</div>
                    <div className="w-1/2">{item.table}</div>
                  </div>
                  <div className="flex justify-between bg-gray-100 px-4 py-2">
                    <div className="w-1/2">Price</div>
                    <div className="w-1/2">
                      {" "}
                      {isAuthenticated ? (
                        <p className="text-lg font-bold">{item.price}</p>
                      ) : (
                        <button
                          onClick={() => setIsLoginModal(true)}
                          className="my-4 rounded-sm bg-theme-blue-logo px-4 py-2 text-sm text-white hover:bg-black hover:text-white"
                        >
                          LOGIN
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <a
                  href={item.route}
                  target="_blank"
                  className="mt-2 block bg-theme-blue-logo py-2 text-center text-white hover:bg-gray-800 hover:text-white group-hover:opacity-100 max-lg:hidden lg:opacity-0"
                >
                  View
                </a>
              </div>
            ) : (
              <>
                <h4 className="mb-2 h-12 text-center">{item.title}</h4>
                {item.images && item.images.main && (
                  <a
                    href={item.route}
                    className="flex h-36  w-full justify-center overflow-hidden hover:border-none hover:border-transparent"
                    target="_blank"
                  >
                    <img
                      alt={item.images.main.alt}
                      className="max-h-[90%] max-w-[90%] object-contain transition-all duration-300 group-hover:scale-110"
                      loading="lazy"
                      src={item.images.main.src}
                    />
                  </a>
                )}
                <div
                  className={`${showSummary ? "justify-between" : "justify-center"} mt-2 flex items-center gap-2`}
                >
                  {showSummary && <p className="text-sm">{item.summary}</p>}
                  {/* TODO:logic for show price only for loged user*/}
                  <div className="my-4">
                    {isAuthenticated ? (
                      <p className="text-lg font-bold">{item.price}</p>
                    ) : (
                      <button
                        onClick={() => setIsLoginModal(true)}
                        className="my-4 rounded-sm bg-theme-blue-logo px-4 py-2 text-sm text-white hover:bg-black hover:text-white"
                      >
                        LOGIN TO SEE PRICE
                      </button>
                    )}
                  </div>
                  {/*  */}
                </div>
                <a
                  href={item.route}
                  target="_blank"
                  className="mt-2 block bg-theme-blue-logo py-2 text-center text-white hover:bg-gray-800 hover:text-white group-hover:opacity-100 max-lg:hidden lg:opacity-0"
                >
                  View
                </a>
              </>
            )}
          </div>
        );
      })}
      {/* modal login */}
      <ModalLogin
        header={`Login`}
        isModalOpen={isLoginModal}
        submitText="Login"
        setIsModalOpen={setIsLoginModal}
        onSuccess={() => setIsLoginModal(false)}
        theme={{
          button: {
            primary:
              "bg-theme-blue text-white hover:bg-black active:bg-black/50",
          },
          header: "bg-black text-white",
          input:
            "border-gray-400 bg-gray-50 text-gray-800 focus:border-theme-blue",
          label: "font-semibold text-gray-800",
          overlay: "bg-black/80",
          window: "bg-white",
          checkbox:
            "border-gray-400 bg-gray-50 rounded hover:bg-gray-200 checked:bg-theme-blue checked:hover:bg-theme-blue/50 checked:focus:bg-theme-blue/50",
        }}
      />
    </div>
  );
}

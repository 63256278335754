// assets
import Image01 from "../assets/images/showcase/01.avif";
import Image02 from "../assets/images/showcase/02.avif";
import Image03 from "../assets/images/showcase/03.avif";
import Image04 from "../assets/images/showcase/04.avif";
import Image05 from "../assets/images/showcase/05.avif";
import Image06 from "../assets/images/showcase/06.avif";
import Image07 from "../assets/images/showcase/07.avif";
import Image08 from "../assets/images/showcase/08.jpg";
import Image09 from "../assets/images/showcase/09.jpg";
import Image10 from "../assets/images/showcase/10.avif";
import Image11 from "../assets/images/showcase/11.jpg";
import Image12 from "../assets/images/showcase/12.jpg";
import Image13 from "../assets/images/showcase/13.jpg";
import Image14 from "../assets/images/showcase/14.avif";
import Image15 from "../assets/images/showcase/15.avif";
import Image16 from "../assets/images/showcase/16.avif";
import Image17 from "../assets/images/showcase/17.avif";
import Image18 from "../assets/images/showcase/18.jpg";

export const showcase = [
  {
    image: Image01,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dw25a67ca0/clp/2023/le-voyage/1.%20CARTIER_LeVoyage_temps2_Visuel2_V2_3_1920x1494.jpg",
  },
  {
    image: Image02,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dwd66076ab/clp/2023/le-voyage/3.%20Side%20module_ONDULE%20RING_1920x1494_2.jpg",
  },
  {
    image: Image03,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dwadcb018d/clp/2023/le-voyage/4.%20Cartier_LeVoyage_visuel1_Caneva_B_3_1920x1494.jpg",
  },
  {
    image: Image04,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dw4bbfa7ff/clp/2023/le-voyage/2.%20CARTIER_LeVoyage_temps2_Visuel1_3_1920x1494.jpg",
  },
  {
    image: Image05,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dw4c547bd7/clp/2023/le-voyage/5.%20Cartier_LeVoyage_Dohora_3_1920x1494.jpg",
  },
  {
    image: Image06,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dw592775ac/clp/2023/le-voyage/8.%20Side%20module_GIRIH_1920x1494_2.jpg",
  },
  {
    image: Image07,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dw2ef41ab6/clp/2022/Beautes%20du%20Monde/1.%20CAMAIL_1920%20x%201494-NEW-2.jpg",
  },
  {
    image: Image08,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dw883baefe/clp/2022/Beautes%20du%20Monde/NM_RYU_1920x1494%20copie.jpg",
  },
  {
    image: Image09,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dw83632e04/clp/2022/Beautes%20du%20Monde/Landing%20Page/07_VISUEL_NOUCHALI_BDM_1920x1494.jpg",
  },
  {
    image: Image10,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dwc6d1d884/clp/2022/Beautes%20du%20Monde/2.%20OCELLE_1920%20x%201494-NEW-2.jpg",
  },
  {
    image: Image11,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dw2c4e2d12/clp/2022/Beautes%20du%20Monde/3.%20COLLIER%20NM_CYCADA_1920x1494.jpg",
  },
  {
    image: Image12,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dw6b09409c/clp/2022/Beautes%20du%20Monde/Landing%20Page/10_VISUEL_APATURA_BDM_1920x1494.jpg",
  },
  {
    image: Image13,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dwa0ed8cbd/clp/2022/Beautes%20du%20Monde/Landing%20Page/06_VISUEL_RITUEL_BDM_1920x1494.jpg",
  },
  {
    image: Image14,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dwcebaa259/clp/2023/le-voyage/11.%20Side%20module_DISTRYSIA_1920x1494.jpg",
  },
  {
    image: Image15,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dw8e96ec5e/clp/2022/Beautes%20du%20Monde/3.%20SPLENDENS_1920%20x%201494.jpg",
  },
  {
    image: Image16,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dwd0c200a6/clp/2023/le-voyage/6.%20Side%20module_VESPRO_1920x1494_2.jpg",
  },
  {
    image: Image17,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dw7ccc571b/clp/2022/Beautes%20du%20Monde/4.%20OBI_1920%20x%201494.jpg",
  },
  {
    image: Image18,
    // "https://www.cartier.com/on/demandware.static/-/Library-Sites-CartierSharedLibrary-BGTJ/default/dw36165050/clp/2022/Beautes%20du%20Monde/Landing%20Page/09_HAUTE-JO_NEL_1920x1494_BEAUTE-DU-MONDE_SIDEMODULE_CAR_CRH7000937.png",
  },
];

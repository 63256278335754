// packages
import { useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import _ from "lodash";
import axios from "axios";

// components
import Alert from "../components/Alert";
import Tabs from "../components/Tabs";

// utils
import { fetchProfile } from "../utils/user";

const PROFILE_KEY = "profile";
const TOKEN_KEY = "token";

const DEFAULT_THEME = {
  // own settings
  button: {
    primary:
      "bg-theme-blue text-white rounded-md hover:bg-black active:bg-black/50",
  },
  checkbox:
    "border-gray-400 bg-gray-50 rounded hover:bg-gray-200 checked:bg-theme-blue checked:hover:bg-theme-blue/50 checked:focus:bg-theme-blue/50",
  container: "bg-theme-blue",
  input:
    "border-gray-400 bg-gray-50 text-gray-800 rounded-md focus:border-theme-blue",
  label: "text-gray-800",
  link: "text-theme-blue hover:text-gray-800 hover:underline",
  title: "text-gray-600 leading-tight tracking-tight",
  window: "rounded-md bg-white",

  // component settings
  tabTheme: {},
};

export default function Login({ theme = {} }) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  const navigate = useNavigate();

  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);

  // login
  const loginFormRef = useRef();
  const handleLogin = async (e) => {
    e.preventDefault();
    setAlertVisible(false);

    const formData = new FormData(loginFormRef.current);
    const entries = Object.fromEntries(formData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          user: { email: entries.email, password: entries.password },
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );

      localStorage.setItem(TOKEN_KEY, response.headers.authorization);

      const profile = await fetchProfile();
      if (profile) {
        localStorage.setItem(PROFILE_KEY, JSON.stringify(profile));
      }

      const requested_path = localStorage.requested_path;
      if (requested_path) {
        localStorage.removeItem("requested_path");
        navigate(requested_path);
      } else {
        navigate("/profile");
      }
    } catch (error) {
      console.error("Error signing in:", error);
      setAlertVisible(true);
      setAlertMessage("Error signing in, please try again.");
    }
  };

  // registration
  // TODO
  const registerFormRef = useRef();
  const handleRegistration = async (e) => {
    e.preventDefault();
    setAlertVisible(false);

    const formData = new FormData(registerFormRef.current);
    const entries = Object.fromEntries(formData);
  };

  //////////////////////////////////////////////////////////////////////////////
  // UI
  // https://flowbite.com/blocks/marketing/login/
  //////////////////////////////////////////////////////////////////////////////

  const tabData = [
    {
      label: "Already registered?",
      content: (
        <div
          className={`${activeTheme.container} mx-auto flex flex-col items-center justify-center p-4 lg:py-6 xl:py-8`}
          // md:h-[calc(100vh-8rem)]
        >
          <div className="w-full md:max-w-md">
            {alertVisible && (
              <Alert
                content={alertMessage}
                type="error"
                visible={alertVisible}
              />
            )}
            <div className={activeTheme.window}>
              <div className="space-y-4 p-6 sm:p-8">
                <h1
                  className={`${activeTheme.title} mb-8 text-xl font-bold md:text-2xl`}
                >
                  Sign In
                </h1>
                <form
                  className="space-y-4"
                  onSubmit={handleLogin}
                  ref={loginFormRef}
                >
                  <div>
                    <label
                      className={`${activeTheme.label} block text-sm font-medium`}
                      htmlFor="lEmail"
                    >
                      Email
                    </label>
                    <input
                      className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
                      id="lEmail"
                      name="email"
                      placeholder="Your email address"
                      required="required"
                      type="email"
                    />
                  </div>
                  <div>
                    <label
                      className={`${activeTheme.label} block text-sm font-medium`}
                      htmlFor="lPassword"
                    >
                      Password
                    </label>
                    <input
                      className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
                      id="lPassword"
                      name="password"
                      placeholder="Your password"
                      required="required"
                      type="password"
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          className={`${activeTheme.checkbox} h-4 w-4 border ring-0 focus:ring-0 active:ring-0`}
                          id="lRemember"
                          type="checkbox"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          className={`${activeTheme.label} block text-sm font-medium`}
                          htmlFor="lRemember"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                    <NavLink
                      className={`${activeTheme.link} text-sm font-medium`}
                      exact="exact"
                      to="/request-password-reset"
                    >
                      Forgot password?
                    </NavLink>
                  </div>
                  <button
                    className={`${activeTheme.button.primary} w-full px-5 py-2.5 text-center text-sm font-bold focus:ring-0`}
                    type="submit"
                  >
                    Sign In
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    // {
    //   label: "Create an account",
    //   content: (
    //     <div
    //       className={`${activeTheme.container} mx-auto flex flex-col items-center justify-center p-4 lg:py-6 xl:py-8`}
    //       // md:h-[calc(100vh-8rem)]
    //     >
    //       <div className="w-full lg:max-w-screen-md">
    //         {alertVisible && (
    //           <Alert
    //             content={alertMessage}
    //             type="error"
    //             visible={alertVisible}
    //           />
    //         )}
    //         <div className={activeTheme.window}>
    //           <div className="space-y-4 p-6 sm:p-8">
    //             <h1
    //               className={`${activeTheme.title} mb-8 text-xl font-bold md:text-2xl`}
    //             >
    //               Sign Up
    //             </h1>
    //             <form
    //               className="space-y-4"
    //               onSubmit={handleRegistration}
    //               ref={registerFormRef}
    //             >
    //               <div className="flex flex-col gap-4 md:flex-row">
    //                 <div className="space-y-4 md:w-1/2">
    //                   <div>
    //                     <label
    //                       className={`${activeTheme.label} block text-sm font-medium`}
    //                       htmlFor="rFullName"
    //                     >
    //                       Full Name
    //                     </label>
    //                     <input
    //                       className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
    //                       id="rFullName"
    //                       name="fullName"
    //                       placeholder="Your full name"
    //                       required="required"
    //                       type="text"
    //                     />
    //                   </div>
    //                   <div>
    //                     <label
    //                       className={`${activeTheme.label} block text-sm font-medium`}
    //                       htmlFor="rEmail"
    //                     >
    //                       Email
    //                     </label>
    //                     <input
    //                       className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
    //                       id="rEmail"
    //                       name="email"
    //                       placeholder="Your email address"
    //                       required="required"
    //                       type="email"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="space-y-4 md:w-1/2">
    //                   <div>
    //                     <label
    //                       className={`${activeTheme.label} block text-sm font-medium`}
    //                       htmlFor="rPassword"
    //                     >
    //                       Password
    //                     </label>
    //                     <input
    //                       className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
    //                       id="rPassword"
    //                       name="password"
    //                       placeholder="Your password"
    //                       required="required"
    //                       type="password"
    //                     />
    //                   </div>
    //                   <div>
    //                     <label
    //                       className={`${activeTheme.label} block text-sm font-medium`}
    //                       htmlFor="rPasswordConfirmation"
    //                     >
    //                       Confirm Password
    //                     </label>
    //                     <input
    //                       className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
    //                       id="rPasswordConfirmation"
    //                       name="passwordConfirmation"
    //                       placeholder="Confirm your password"
    //                       required="required"
    //                       type="password"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="flex items-center justify-between">
    //                 <div className="flex items-start">
    //                   <div className="flex h-5 items-center">
    //                     <input
    //                       className={`${activeTheme.checkbox} h-4 w-4 border ring-0 focus:ring-0 active:ring-0`}
    //                       id="rAgree"
    //                       type="checkbox"
    //                     />
    //                   </div>
    //                   <div className="ml-3 text-sm">
    //                     <label
    //                       className={`${activeTheme.label} block text-sm font-medium`}
    //                       htmlFor="rAgree"
    //                     >
    //                       I agree to{" "}
    //                       <NavLink
    //                         className={`${activeTheme.link} text-sm font-medium`}
    //                         exact="exact"
    //                         to="#"
    //                       >
    //                         Terms of Use
    //                       </NavLink>{" "}
    //                       and{" "}
    //                       <NavLink
    //                         className={`${activeTheme.link} text-sm font-medium`}
    //                         exact="exact"
    //                         to="#"
    //                       >
    //                         Privacy Policy
    //                       </NavLink>
    //                     </label>
    //                   </div>
    //                 </div>
    //               </div>
    //               <button
    //                 className={`${activeTheme.button.primary} w-full px-5 py-2.5 text-center text-sm font-bold focus:ring-0`}
    //                 type="submit"
    //               >
    //                 Sign Up
    //               </button>
    //             </form>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  return <Tabs data={tabData} theme={activeTheme.tabTheme} />;
}

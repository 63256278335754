// assets
import BannerImage from "../assets/images/banners/gift-box.webp";

// components (template)
// import Header from "../components/Header";
// import ImageBanner from "../components/ImageBanner";
import Hero from "../components/Hero";
import StandardContainer from "../components/StandardContainer";

// data
import { contacts } from "../data/contacts";

export default function Contact() {
  const content = (
    <>
      <Hero
        form="true"
        title="Contact"
        // image={BannerImage}
        content={[
          "Founded in 2006, Diamonds for Life, Inc. is a diamond wholesaler based in the heart of the downtown Los Angeles jewelry district.",
          "The Diamonds for Life difference is our commitment to serving you. We make buying diamonds on memo easy and fast, with little to no risk to you.",
        ]}
      />
      <div className="my-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:my-8 lg:grid-cols-3 lg:gap-8">
        {contacts.map((contact, index) => {
          return (
            <div key={index}>
              <h2 className="uppercase">{contact.title}</h2>
              <h3 className="mb-1 mt-2 text-lg">{contact.subtitle}</h3>
              {contact.content && (
                <ul className="flex flex-col space-y-1">
                  {contact.content.map((item, index2) => {
                    return <li key={index2}>{item}</li>;
                  })}
                </ul>
              )}
              <a
                href={contact.link.href}
                className="text-lg"
                dangerouslySetInnerHTML={{ __html: contact.link.text }}
              ></a>
            </div>
          );
        })}
      </div>
    </>
  );

  return (
    <>
      <>
        <StandardContainer content={content} />
      </>
    </>
  );
}

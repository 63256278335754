// packages
import { useState } from "react";
// import { loremIpsum } from "lorem-ipsum";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// assets
import BannerImage from "../assets/images/banners/showcase.avif";

// components (template)
import HeroWithImage from "../components/HeroWithImage";
import StandardContainer from "../components/StandardContainer";

// data
import { showcase } from "../data/showcase";

export default function Showcase() {
  //////////////////////////////////////////////////////////////////////////////
  // lightbox
  //////////////////////////////////////////////////////////////////////////////

  const [index, setIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  const slides = showcase.map((item) => {
    return { src: item.image };
  });

  const openLightbox = (clickedIndex) => {
    setIndex(clickedIndex);
    setOpen(true);
  };

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const content = (
    <div className="my-2 grid grid-cols-1 gap-2 sm:grid-cols-2 md:my-4 md:grid-cols-3 md:gap-4 lg:my-6">
      {showcase.map((item, index) => {
        return (
          <div className="overflow-hidden" key={index}>
            <img
              alt=""
              className="cursor-pointer transition-transform duration-300 hover:scale-125"
              loading="lazy"
              onClick={() => openLightbox(index)}
              src={item.image}
            />
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <HeroWithImage
        title="Extraordinary Creations"
        subtitle="Explore our catalog of exceptional masterpieces"
        // description={loremIpsum({
        //   count: 2,
        //   sentenceLowerBound: 10,
        //   sentenceUpperBound: 10,
        // })}
        image={{
          src: BannerImage,
        }}
      />
      <StandardContainer content={content} />
      <Lightbox
        close={() => {
          setIndex(-1);
          setOpen(false);
        }}
        controller={{ closeOnBackdropClick: true }}
        index={index}
        open={open}
        slides={slides}
      />
    </>
  );
}

// assets
import {
  FaFacebook,
  FaInstagram,
  // FaPinterest,
  // FaTiktok,
  // FaYoutube,
} from "react-icons/fa";

export const navbar = [
  {
    href: "/diamonds",
    text: "Diamonds",
  },
  {
    href: "/about",
    text: "About",
  },
  {
    href: "/contact",
    text: "Contact",
  },
];

export const footer = {
  Inventory: {
    Diamonds: "/diamonds",
    // Showcase: "/showcase",
    // Jewelry: "/jewelry",
  },
  Information: {
    "How To Order": "/process",
    FAQ: "/faq",
  },
  Company: {
    About: "/about",
    Contact: "/contact",
    // Locations: "/locations",
    // "Client Login": "/sign-in",
  },
  Contact: {
    Call: "tel:+12139555900",
    Chat: "https://wa.me/12138107333",
  },
  // Legal: {
  //   "Terms of Use": "#",
  //   "Privacy Policy": "#",
  //   "Conditions of Sale": "#",
  // },
};

export const social = {
  facebook: {
    icon: <FaFacebook />,
    url: "https://www.instagram.com/_diamondsforlife/",
  },
  instagram: {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/_diamondsforlife/",
  },
  // pinterest: {
  //   icon: <FaPinterest />,
  //   url: "#",
  // },
  // tiktok: {
  //   icon: <FaTiktok />,
  //   url: "#",
  // },
  // youtube: {
  //   icon: <FaYoutube />,
  //   url: "#",
  // },
};

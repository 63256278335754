export const about = {
  "About Us": [
    "Founded in 2006 by Yariv Janfaza, Diamonds for Life, Inc. is a diamond wholesaler based in the heart of the downtown Los Angeles jewelry district.",
    "We maintain a collection of thousands of loose diamonds in all shapes and sizes ranging from .50 to 10 carat, with a special emphasis on GIA and EGL USA certified stones.",
    "Our number one priority is to help your business. We do that by providing you the best service and super-low pricing. It's what makes us different and why our customers love working with us.",
  ],
  "What We Do": [
    'We make your buying experience fast, simple, and stress-free with our "one text" memo orders on our entire inventory.',
    "Simply text us what you need. We promptly send you a text confirming your order, and then deliver the items to you on memo with free overnight shipping (within the US).",
    "We do our utmost to ensure your satisfaction. From the moment you decide what merchandise to order, until even after you sell to your customers, we try to protect you for life. If you need to exchange merchandise at any time down the road, just let us know and we'll figure out a solution with our accommodating policies. We're here for you.",
  ],
};

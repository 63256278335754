// packages
import { useEffect, useState } from "react";
import ReactSlider from "react-slider";
import _ from "lodash";

const DEFAULT_THEME = {
  label: {
    active: "text-theme-blue",
    inactive: "text-gray-400",
  },
  mark: "bg-theme-blue/20",
  thumb: "bg-theme-blue text-white rounded-full focus:ring-theme-blue/40",
  title: "text-theme-blue",
  track: {
    active: "bg-theme-blue",
    inactive: "bg-theme-red",
  },
};

// https://zillow.github.io/react-slider/

export default function RangeValues({
  callback,
  options,
  selectedValues,
  theme = {},
  title,
}) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  const marks = Array.from(Array(_.size(options) - 1).keys());

  const [markMax, setMarkMax] = useState(
    selectedValues.length > 0
      ? _.indexOf(_.values(options), _.last(selectedValues))
      : _.size(options) - 1,
  );

  const [markMin, setMarkMin] = useState(
    selectedValues.length > 0
      ? _.indexOf(_.values(options), _.first(selectedValues))
      : 0,
  );

  const [values, setValues] = useState(_.values(options));
  const [valuesOld, setValuesOld] = useState(values);

  useEffect(() => {
    setValues(_.values(options).slice(markMin, markMax + 1));
  }, [markMax, markMin]);

  const handleSliderAfterChange = (_values, _thumbIndex) => {
    if (!_.isEqual(values, valuesOld)) {
      callback(values);
    }
  };

  const handleSliderBeforeChange = (_values, _thumbIndex) => {
    setValuesOld(values);
  };

  const handleSliderChange = (values, _thumbIndex) => {
    const indexMax = values[1];
    const indexMin = values[0];
    setMarkMax(indexMax);
    setMarkMin(indexMin);
  };

  const renderMark = (props) => {
    return <div {...props} className={`${activeTheme.mark} h-2 w-1`} />;
  };

  const renderThumb = (props, _state) => {
    return (
      <div
        {...props}
        className={`${activeTheme.thumb} h-3 w-3 cursor-grab font-bold focus:ring-2 focus:ring-offset-2
        `}
      />
    );
  };

  const renderTrack = (props, _state) => {
    return (
      <div
        {...props}
        className={`${
          props.key === "track-2"
            ? `${activeTheme.track.inactive} h-0.5`
            : props.key === "track-1"
              ? `${activeTheme.track.active} h-1`
              : `${activeTheme.track.inactive} h-0.5`
        }`}
      />
    );
  };

  return (
    <div className="flex flex-col justify-between">
      <p className={`${activeTheme.title} text-sm font-bold`}>{title}</p>
      <ol className="mt-2 flex justify-between">
        {_.map(_.keys(options), (key, index) => {
          return (
            <li
              className={`${
                index >= markMin && index <= markMax
                  ? activeTheme.label.active
                  : activeTheme.label.inactive
              } text-xs font-bold`}
              key={index}
            >
              {key}
            </li>
          );
        })}
      </ol>
      <ReactSlider
        className="mt-3 flex items-center"
        marks={marks}
        max={_.size(options) - 1}
        min={0}
        onAfterChange={handleSliderAfterChange}
        onBeforeChange={handleSliderBeforeChange}
        onChange={handleSliderChange}
        renderMark={renderMark}
        renderThumb={renderThumb}
        renderTrack={renderTrack}
        value={[markMin, markMax]}
      />
    </div>
  );
}

// packages
import { NavLink } from "react-router-dom";

export default function Categories({
  data,
  title,
  path,
  param,
  imageCssClasses,
}) {
  return (
    <>
      {title && (
        <h2 className="mb-4 border-none text-center text-2xl md:text-3xl">
          {title}
        </h2>
      )}
      <div className="grid grid-cols-3 gap-4 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-10">
        {data.map((item, index) => {
          return (
            <NavLink
              className="group flex flex-col items-center gap-2 overflow-hidden border-none text-gray-700"
              key={index}
              to={`${path}?${param}=${item.code}`}
            >
              <img
                alt={item.name}
                className={`${imageCssClasses} h-full object-scale-down pt-1 transition-all duration-300 group-hover:scale-110`}
                src={item.image}
              />
              <p className="font-semibold transition-all duration-300 group-hover:translate-y-1">
                {item.name}
              </p>
            </NavLink>
          );
        })}
      </div>
    </>
  );
}

const DEFAULT_THEME = {
  box: "border border-gray-300 grid",
  button: "py-1 hover:bg-theme-blue hover:text-white border",
  buttonActive: "bg-theme-blue text-white",
  buttonInactive: "bg-gray-100 text-theme-blue",
  gridClass: "grid-cols-3",
  textClass: "text-sm",
};

export default function PickItems({
  callback,
  options,
  selectedValues,
  theme = {},
  title,
}) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  return (
    <div className="space-y-2">
      <div className="text-sm font-bold">{title}</div>
      <div className={`${activeTheme.gridClass} ${activeTheme.box}`}>
        {Object.keys(options).map((key, index) => {
          const isActive = selectedValues.includes(options[key]);

          return (
            <button
              key={index}
              className={`${activeTheme.textClass} ${activeTheme.button} ${isActive ? activeTheme.buttonActive : activeTheme.buttonInactive}`}
              onClick={() => callback(options[key])}
            >
              {key}
            </button>
          );
        })}
      </div>
    </div>
  );
}

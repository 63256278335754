// assets
import BannerImage from "../assets/images/banners/showrooms.avif";

// components (template)
import Header from "../components/Header";
import ImageBanner from "../components/ImageBanner";
import StandardContainer from "../components/StandardContainer";
import BannerDiamonds from "../assets/images/banners/diamonds.webp";

// data
import { about } from "../data/about";

export default function About() {
  const content = (
    <>
      {Object.entries(about).map(([sectionTitle, items]) => (
        <section key={sectionTitle} className="mb-4 mt-6">
          <h2 className="text-xl font-bold">{sectionTitle}</h2>
          <div className="mt-2 flex flex-col gap-2">
            {items.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
        </section>
      ))}
    </>
  );
  return (
    <>
      <Header title="About" />
      <ImageBanner image={BannerDiamonds} />
      <StandardContainer content={content} />
    </>
  );
}

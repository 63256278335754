// assets
import BannerImage from "../assets/images/banners/diamonds.webp";

// components (template)
import Header from "../components/Header";
import ImageBanner from "../components/ImageBanner";
import StandardContainer from "../components/StandardContainer";

// data
import { process } from "../data/process";

export default function Process() {
  const content = (
    <>
      {Object.entries(process).map(([sectionTitle, items]) => (
        <section key={sectionTitle} className="mb-4 mt-6">
          <h2 className="text-xl font-bold">{sectionTitle}</h2>
          <div className="mt-2 flex flex-col gap-2">
            {items.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
        </section>
      ))}
    </>
  );
  return (
    <>
      <Header title="How To Order" />
      <ImageBanner image={BannerImage} />
      <StandardContainer content={content} />
    </>
  );
}

import { MdOutlineClose, MdOutlineForwardToInbox } from "react-icons/md";

const DEFAULT_THEME = {
  button: {
    primary:
      "bg-theme-blue text-white rounded-md hover:bg-black active:bg-black/50",
  },
  header: "bg-theme-blue text-white rounded-t-md",
  input:
    "border-gray-400 bg-gray-50 text-gray-800 rounded-md focus:border-theme-blue",
  label: "text-gray-800",
  overlay: "bg-black/40",
  window: "bg-white rounded-md",
};

export default function Form({ submitText, comments, theme = {} }) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  return (
    <>
      {/* to do after make it for modal too */}
      <form
        className="space-y-2 p-2 md:space-y-4 md:p-4"
        // onSubmit={handleSubmit}
        // ref={formRef}
      >
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          {/* <div className="space-y-2 md:w-1/2 md:space-y-4"> */}
          <div>
            <label
              className={`${activeTheme.label} block text-sm font-medium`}
              htmlFor="iName"
            >
              Name
            </label>
            <input
              className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
              id="iName"
              name="name"
              placeholder="Your full name"
              required="required"
              type="text"
            />
          </div>
          <div>
            <label
              className={`${activeTheme.label} block text-sm font-medium`}
              htmlFor="iEmail"
            >
              Email
            </label>
            <input
              className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
              id="iEmail"
              name="email"
              placeholder="Your email address"
              required="required"
              type="email"
            />
          </div>
          {/* </div> */}
          {/* <div className="space-y-2 md:w-1/2 md:space-y-4"> */}
          <div className="">
            <label
              className={`${activeTheme.label} block text-sm font-medium`}
              htmlFor="iPhone"
            >
              Phone
            </label>
            <input
              className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
              id="iPhone"
              name="phone"
              placeholder="Your phone number"
              type="tel"
            />
          </div>
          <div>
            <label
              className={`${activeTheme.label} block text-sm font-medium`}
              htmlFor="iComments"
            >
              Comments
            </label>
            <input
              className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
              id="iComments"
              name="comments"
              placeholder="Your questions or comments"
              type="text"
            />
          </div>
          {/* </div> */}
        </div>
        <button
          className={`${activeTheme.button.primary} group flex w-full items-center justify-center gap-2 px-4 py-3 font-bold focus:ring-0 md:text-lg`}
          type="submit"
        >
          {submitText}
          <MdOutlineForwardToInbox
            className="shrink-0 group-hover:scale-110"
            size="20"
          />
        </button>
      </form>
    </>
  );
}

// packages
import { Link } from "react-router-dom";
import { useState } from "react";

import {
  MdOutlineAddShoppingCart,
  MdOutlineCall,
  MdOutlineFavorite,
  MdOutlineMail,
  MdOutlineRemoveShoppingCart,
  MdOutlineShare,
} from "react-icons/md";

// assets
import IconGift from "../../assets/icons/gift.svg";
import IconReturn from "../../assets/icons/return.svg";
import IconShipping from "../../assets/icons/shipping.svg";

// components (template)
import ModalInquiry from "../../components/ModalInquiry";
import ModalLogin from "../ModalLogin";

// utils
import { toggleBookmark } from "../../shared/utils/bookmark";
import {
  isInQuote,
  loadQuoteItems,
  toggleQuoteItem,
} from "../../shared/utils/quote";
import { useCompanySettings } from "../../shared/utils/company";

// TODO: this is temporary
const currentDate = new Date();
currentDate.setDate(currentDate.getDate() + 3);
const deliveryDate = currentDate.toLocaleString("en-US", {
  weekday: "short",
  month: "short",
  day: "numeric",
});

export default function ItemDetails({ dataAsParam }) {
  const [data, setData] = useState(dataAsParam);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [loginAction, setLoginAction] = useState(false);
  const [_quoteItems, setQuoteItems] = useState(loadQuoteItems());

  const isTokenValid = () => {
    const token = localStorage.getItem("token");
    return token !== null;
  };

  const { companySettings } = useCompanySettings();
  const detailsFieldsSetting = companySettings?.find(
    (item) => item.setting_name === `${data.type}_fields`,
  );

  const detailsFields = detailsFieldsSetting
    ? detailsFieldsSetting.setting_value === "all"
      ? "all"
      : detailsFieldsSetting.setting_value
          .split(", ")
          .map((field) => field.trim())
    : "all";

  const toggleBookmarkWrapper = async (item) => {
    try {
      if (isTokenValid()) {
        item = await toggleBookmark(item);
        setData((prevData) => {
          return { ...prevData, is_bookmarked: item.is_bookmarked };
        });
      } else {
        setIsLoginModal(true);
        setLoginAction("Bookmark");
      }
    } catch (error) {
      console.error("Error toggling bookmark", error);
    }
  };

  const toggleQuoteHandler = () => {
    try {
      if (isTokenValid()) {
        const updatedItem = toggleQuoteItem(data.type, data);
        setData((prevData) => ({ ...prevData, ...updatedItem }));
        setQuoteItems(loadQuoteItems());
      } else {
        setIsLoginModal(true);
        setLoginAction("Quote");
      }
    } catch (error) {
      console.error("Error toggling quote", error);
    }
  };

  return (
    <>
      <div className="flex items-start justify-between">
        <div>
          <h2 className="text-2xl font-bold">{data.summary}</h2>
          {/* TODO:logic for show price only for loged user*/}
          <div className="my-4">
            {isTokenValid() ? (
              <h3 className="mb-4 mt-2 border-none text-3xl font-bold text-theme-blue-logo">
                {data.price}
              </h3>
            ) : (
              <button
                onClick={() => setIsLoginModal(true)}
                className="text-md my-4 rounded-sm py-2 font-bold text-theme-blue-logo hover:text-black"
              >
                LOGIN TO SEE PRICE
              </button>
            )}
          </div>
          {/*  */}
          {data.type === "jewelry" && data.centerDescription && (
            <h4 className="mb-4 border-none text-xl font-semibold">
              <b>Center Stone:</b> {data.centerDescription}
            </h4>
          )}
          {data.type === "jewelry" && data.sideDescription && (
            <h4 className="mb-4 border-none text-xl font-semibold">
              <b>Side Details:</b> {data.sideDescription}
            </h4>
          )}
        </div>
        <button
          onClick={() => toggleBookmarkWrapper(data)}
          title={`${data.is_bookmarked ? "Remove from" : "Add to"} favorites`}
        >
          {data.is_bookmarked ? (
            <MdOutlineFavorite
              className="shrink-0 fill-theme-blue-logo hover:scale-110 hover:fill-gray-800"
              size="32"
            />
          ) : (
            <MdOutlineFavorite
              className="shrink-0 fill-gray-800 hover:scale-110 hover:fill-theme-blue-logo"
              size="32"
            />
          )}
        </button>
      </div>
      {/* shopping buttons */}
      <div className="flex gap-x-2 md:gap-x-4">
        <button
          className={`${
            isInQuote(data.type, data)
              ? "bg-theme-blue-logo hover:bg-gray-800"
              : "bg-gray-800 hover:bg-theme-blue-logo"
          } flex w-1/2 items-center justify-center gap-2 p-3 text-base font-semibold text-white hover:text-white`}
          onClick={toggleQuoteHandler}
          title={`${
            isInQuote(data.type, data) ? "Remove from" : "Add to"
          } quote`}
        >
          {isInQuote(data.type, data) ? (
            <>
              <MdOutlineRemoveShoppingCart
                className="hidden shrink-0 lg:inline-block"
                size="20"
              />
              Remove From Quote
            </>
          ) : (
            <>
              <MdOutlineAddShoppingCart
                className="hidden shrink-0 lg:inline-block"
                size="20"
              />
              Add To Quote
            </>
          )}
        </button>
        <button
          className="flex w-1/2 justify-center bg-gray-800 p-3 text-base font-semibold text-white hover:bg-theme-blue-logo hover:text-white"
          onClick={() => setIsModalOpen(true)}
        >
          Inquire <span className="hidden lg:inline-block">&rarr;</span>
        </button>
      </div>
      {/* shopping details */}
      <ul className="mt-2 flex flex-col gap-y-2 divide-y font-semibold">
        <li className="flex items-center gap-x-2 pt-2">
          <img alt="" className="w-5" src={IconShipping} />
          Free Shipping
        </li>
        <li className="flex items-center gap-x-2 pt-2">
          <img alt="" className="w-5" src={IconReturn} />
          Free Returns
        </li>
        <li className="flex items-center gap-x-2 pt-2">
          <img alt="" className="w-5" src={IconGift} />
          Delivery by {deliveryDate}
        </li>
      </ul>
      {/* sharing */}
      <ul className="mt-4 grid grid-cols-3 justify-between divide-x divide-gray-200 bg-gray-50 p-2">
        <li>
          <a
            className="a2a_dd flex items-center justify-center gap-2 border-none py-2 font-bold text-black hover:text-theme-blue-logo"
            href="#"
          >
            <MdOutlineShare className="shrink-0" size={16} />
            <span>Share</span>
          </a>
        </li>
        <li>
          <a
            className="flex items-center justify-center gap-2 border-none py-2 font-bold text-black hover:text-theme-blue-logo"
            href="mailto:info@example.com"
          >
            <MdOutlineMail className="shrink-0" size={16} />
            <span>Email</span>
          </a>
        </li>
        <li>
          <a
            className="flex items-center justify-center gap-2 border-none py-2 font-bold text-black hover:text-theme-blue-logo"
            href="tel:+18001112233"
          >
            <MdOutlineCall className="shrink-0" size={16} />
            <span>Call</span>
          </a>
        </li>
      </ul>
      {/* item details */}
      <dl className="mt-2 flex flex-col divide-y">
        {Object.keys(data.overview)
          .filter(
            (key) =>
              !_.isEmpty(data.overview[key]) &&
              (detailsFields === "all" || detailsFields.includes(key)),
          )
          .map((key, index) => (
            <div
              className={`flex w-full items-center justify-between ${
                key === "gemstone" ? "py-0" : "py-2"
              }`}
              key={index}
            >
              <dt className="inline-block text-xs font-semibold md:text-sm">
                {_.startCase(key)}
              </dt>
              <dd className="inline-block font-bold">
                {key === "gemstone" ? (
                  <div className="flex border border-theme-blue">
                    {["Natural Diamond", "Lab-Grown Diamond"].map(
                      (gemstoneOption, index) => (
                        <span
                          key={index}
                          className={`px-2 py-0 ${
                            data.overview[key] === gemstoneOption
                              ? "bg-theme-blue text-white"
                              : ""
                          }`}
                        >
                          {gemstoneOption.replace(/ Diamond/, "")}
                        </span>
                      ),
                    )}
                  </div>
                ) : (
                  data.overview[key]
                )}
              </dd>
            </div>
          ))}
        {/* lab & certificate */}
        {data.lab && (
          <div className="flex w-full items-center justify-between pt-2">
            <dt className="inline-block text-xs font-semibold md:text-sm">
              {data.certificateUrl ? (
                <a href={data.certificateUrl} target="_blank">
                  Certified by {data.lab}
                </a>
              ) : (
                <>Certified by {data.lab}</>
              )}
            </dt>
            {!data.certificateUrl && (
              <dd className="inline-block font-bold">
                {data.data.lab_cert_no}
              </dd>
            )}
          </div>
        )}
      </dl>

      {/* modal login */}
      <ModalLogin
        header={`Login`}
        isModalOpen={isLoginModal}
        submitText="Login"
        setIsModalOpen={setIsLoginModal}
        onSuccess={
          loginAction == "Quote"
            ? toggleQuoteHandler
            : () => toggleBookmarkWrapper(data)
        }
        theme={{
          button: {
            primary:
              "bg-theme-blue text-white hover:bg-black active:bg-black/50",
          },
          header: "bg-black text-white",
          input:
            "border-gray-400 bg-gray-50 text-gray-800 focus:border-theme-blue",
          label: "font-semibold text-gray-800",
          overlay: "bg-black/80",
          window: "bg-white",
          checkbox:
            "border-gray-400 bg-gray-50 rounded hover:bg-gray-200 checked:bg-theme-blue checked:hover:bg-theme-blue/50 checked:focus:bg-theme-blue/50",
        }}
      />

      {/* modal */}
      <ModalInquiry
        header={`Inquire About ${data.title}`}
        isModalOpen={isModalOpen}
        submitText="Submit Inquiry"
        setIsModalOpen={setIsModalOpen}
        theme={{
          button: {
            primary:
              "bg-theme-blue-logo text-white hover:bg-black active:bg-black/50",
          },
          header: "bg-black text-white",
          input:
            "border-gray-400 bg-gray-50 text-gray-800 focus:border-theme-blue-logo",
          label: "font-semibold text-gray-800",
          overlay: "bg-black/80",
          window: "bg-white",
        }}
      />
    </>
  );
}

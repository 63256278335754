// packages
import { Component } from "react";

// classes
import Diamond from "../classes/Diamond";

// contexts
import { ComparisonProvider } from "../contexts/ComparisonContext";
import { SearchProvider } from "../contexts/SearchContext";

export default class DiamondsWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        // sorting
        sortBy: "",
        sortDir: "",
        // single values
        caratsMax: "",
        caratsMin: "",
        depthMax: "",
        depthMin: "",
        description: "",
        priceMax: "",
        priceMin: "",
        // stoneType: "",
        tableMax: "",
        tableMin: "",
        // multiple values
        clarity: [],
        color: [],
        cut: [],
        fluorescence: [],
        lab: [],
        polish: [],
        shape: [],
        symmetry: [],
      },
    };
  }

  constructRequestUrl = (params, page) => {
    // apply URL params, if available
    const queryParams = new URLSearchParams(window.location.search);

    const shapeParam = queryParams.get("shape");
    if (shapeParam) {
      params = { ...params, shape: [shapeParam] };
    }

    // construct API URL
    const url = new URL(`${process.env.REACT_APP_API_URL}/stones.json`);
    url.searchParams.append("company_id", process.env.REACT_APP_COMPANY_ID);

    // pagination & sorting
    url.searchParams.append("page", page);
    url.searchParams.append("sort_by", params.sortBy);
    url.searchParams.append("sort_dir", params.sortDir);

    // single values
    url.searchParams.append("carats_max", params.caratsMax);
    url.searchParams.append("carats_min", params.caratsMin);
    url.searchParams.append("depth_max", params.depthMax);
    url.searchParams.append("depth_min", params.depthMin);
    url.searchParams.append("description", params.description);
    url.searchParams.append("price_max", params.priceMax);
    url.searchParams.append("price_min", params.priceMin);
    url.searchParams.append("stone_type", "DIAM");
    url.searchParams.append("table_max", params.tableMax);
    url.searchParams.append("table_min", params.tableMin);

    // multiple values
    url.searchParams.append("clarity", params.clarity.join(","));
    url.searchParams.append("color", params.color.join(","));
    url.searchParams.append("cut", params.cut.join(","));
    url.searchParams.append("fluorescence", params.fluorescence.join(","));
    url.searchParams.append("lab", params.lab.join(","));
    url.searchParams.append("polish", params.polish.join(","));
    url.searchParams.append("shape", params.shape.join(","));
    url.searchParams.append("symmetry", params.symmetry.join(","));

    return url;
  };

  expandAdvancedSearch = (params) => {
    const expand =
      (params.fluorescence && params.fluorescence.length > 0) ||
      (params.lab && params.lab.length > 0) ||
      (params.polish && params.polish.length > 0) ||
      (params.symmetry && params.symmetry.length > 0) ||
      params.depthMax ||
      params.depthMin ||
      params.description ||
      params.tableMax ||
      params.tableMin;
    return !!expand;
  };

  formatData = (data) => {
    const { routeShow } = this.props;

    return data.map((item) => {
      return new Diamond(item, routeShow);
    });
  };

  render() {
    const { params } = this.state;
    const { page: Page, routeShow } = this.props;

    return (
      <SearchProvider
        searchType="diamonds"
        params={params}
        requestUrl={this.constructRequestUrl}
        formatData={this.formatData}
        routeProperties="stone-properties"
        routeShow={routeShow}
        expandAdvancedSearch={this.expandAdvancedSearch}
      >
        <ComparisonProvider comparisonType="diamonds">
          {Page && <Page />}
        </ComparisonProvider>
      </SearchProvider>
    );
  }
}

export const contacts = [
  {
    title: "Call",
    subtitle: "Business hours",
    content: [
      "Weekdays: 9 a.m. - 5 p.m.",
      "Saturday: closed",
      "Sunday: closed",
    ],
    link: {
      href: "tel:+12139555900",
      text: "(213) 955-5900",
    },
  },
  {
    title: "Visit",
    subtitle: "Visit our flagship store",
    link: {
      href: "https://maps.app.goo.gl/gt55dmuqZzqsU4Vo9",
      text: "550 South Hill Street<br />Suite 1650<br />Los Angeles, CA 90013",
    },
  },
  {
    title: "Trusted Since 2006",
    subtitle: "Arrange a personalized appointment",
    link: {
      href: "#",
      text: "Rapnet ID: 66819<br />Polygon ID: 136539<br/>JBT ID: 2418390",
    },
  },
  {
    title: "Email",
    subtitle: "Reach our customer service",
    link: {
      href: "mailto:info@diamondsforlife.com",
      text: "Send a message",
    },
  },
  {
    title: "Chat",
    subtitle: "Connect with an ambassador",
    link: {
      href: "https://wa.me/12138107333",
      text: "Start chatting",
    },
  },
  {
    title: "FAQ",
    subtitle: "Find answers to commonly asked questions",
    link: {
      href: "/faq",
      text: "Explore FAQ",
    },
  },
];

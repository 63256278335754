// packages
import { NavLink } from "react-router-dom";

// assets
import Affiliate1 from "../assets/images/affiliates/footer-affiliate-1.png";
import Affiliate2 from "../assets/images/affiliates/footer-affiliate-2.png";
import Affiliate3 from "../assets/images/affiliates/footer-affiliate-3.png";
import Affiliate4 from "../assets/images/affiliates/footer-affiliate-4.png";
import Affiliate5 from "../assets/images/affiliates/footer-affiliate-5.png";
import Affiliate6 from "../assets/images/affiliates/footer-affiliate-6.png";
import Affiliate7 from "../assets/images/affiliates/footer-affiliate-7.png";
import Affiliate8 from "../assets/images/affiliates/footer-affiliate-8.png";
import Logo from "../assets/logos/logo-bottom.png";

// components
import StandardContainer from "./StandardContainer";

// data
import { meta } from "../data/meta";
import { footer, social } from "../data/nav";

export default function Footer() {
  const content = (
    <>
      <div className="grid grid-cols-2 gap-4 py-4 md:grid-cols-5 md:gap-8 md:py-8">
        {Object.keys(footer).map((category, index) => (
          <div key={index}>
            <h2 className="mb-2 text-base uppercase">{category}</h2>
            <ul className="flex flex-col gap-y-1 md:gap-y-2">
              {Object.keys(footer[category]).map((item, itemIndex) => (
                <li key={itemIndex}>
                  <NavLink to={footer[category][item]}>{item}</NavLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
        <div className="flex items-center justify-center">
          <a href="/" className="border-none hover:border-none">
            <img
              src={Logo}
              alt="logo"
              className="h-28 self-end object-contain"
            />
          </a>
        </div>
      </div>
      <div className="grid grid-cols-2 justify-between bg-white px-4 py-6 max-lg:gap-2 md:grid-cols-4 lg:flex lg:flex-row">
        <img src={Affiliate1} alt="" className="object-contain" />
        <img src={Affiliate2} alt="" className="object-contain" />
        <img src={Affiliate3} alt="" className="object-contain" />
        <img src={Affiliate4} alt="" className="object-contain" />
        <img src={Affiliate5} alt="" className="object-contain" />
        <img src={Affiliate6} alt="" className="object-contain" />
        <img src={Affiliate7} alt="" className="object-contain" />
        <img src={Affiliate8} alt="" className="object-contain" />
      </div>
      <div className="bg-white p-4 max-sm:space-y-2 sm:flex sm:items-center sm:justify-between">
        <ul className="flex gap-x-4 max-sm:justify-center">
          {Object.keys(social).map((link, index) => (
            <li key={index}>
              <a
                className="text-gray-400 hover:text-theme-blue-logo"
                href={social[link].url}
              >
                {social[link].icon}
              </a>
            </li>
          ))}
        </ul>
        <p className="text-xs max-sm:text-center sm:order-first">
          &copy; {new Date().getFullYear()} {meta.name}. All rights reserved.
          Powered by White Diamond Inc
        </p>
      </div>
    </>
  );

  return (
    <footer className="bg-gray-50">
      <StandardContainer content={content} />
    </footer>
  );
}
